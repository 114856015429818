import React, { useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const AuthContext = React.createContext();

export function UseAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [AuthUser, setAuthUser] = useLocalStorage("auth", {});

  function createAuthUser(user) {
    setAuthUser(user);
  }

  return (
    <AuthContext.Provider value={{ AuthUser, createAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
}

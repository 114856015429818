import { AxiosDelete, AxiosGet, AxiosPatch, AxiosPost } from "../axios/Axios";

const prefix = "/user";

export const getDashboard = (data) => {
  return AxiosPost(`${prefix}/dashboard`, data);
};
export const getUserProfile = () => {
  return AxiosGet(`${prefix}/profile`);
};
export const getUsers = (data) => {
  return AxiosPost(`${prefix}/list`, data);
};
export const addUser = (data) => {
  return AxiosPost(`${prefix}/create`, data);
};
export const editUser = (data, id) => {
  return AxiosPatch(`${prefix}/edit/` + id, data);
};
export const getUserById = (id) => {
  return AxiosGet(`${prefix}/` + id);
};
export const deleteUserById = (id) => {
  return AxiosDelete(`${prefix}/delete/${id}`);
};
export const getRols = (data) => {
  return AxiosPost(`/role/list`, data);
};

export const updateProfile = (data) => {
  return AxiosPatch(`${prefix}/update-profile`, data);
};

export const changePasswords = (data) => {
  return AxiosPost(`${prefix}/change-password`, data);
};

import axios from "axios";
import { AxiosPost } from "../axios/Axios";

import { API_URL } from "../config";
import { PREFIX } from "../hooks/useLocalStorage";

const prefix = "/user";

export const apiConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

const register = (request) => {
  return axios.post(API_URL + "register", request, apiConfig);
};

const login = (data) => {
  return AxiosPost(`${prefix}/login`, data);
};

const forgotPasswords = (data) => {
  return AxiosPost(`${prefix}/forgot-password`, data);
};

const resetPasswords = (data) => {
  return AxiosPost(`${prefix}/reset-password`, data);
};

const logout = () => {
  localStorage.removeItem(PREFIX + "auth");
};

const authService = {
  register,
  login,
  logout,
  forgotPasswords,
  resetPasswords,
};

export default authService;

import { userActions } from "../actions/userActions";

const initialState = {
  isRequesting: false,
  errors: undefined,
  data: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case userActions.ROLE_SUCCESS:
      return Object.assign(
        {},
        {
          ...state,
          isRequesting: false,
          errors: undefined,
          roles: action.payload,
        }
      );
    default:
      return state;
  }
}
